<template>
  <section class="brace">
    <div
      v-if="title"
      class="flex items-end rounded-t-2.5xl h-22 md:h-36 md:items-center"
      :class="`${backgroundColor} ${invert ? 'md:ml-8.3/100vw lg:ml-1/3' : 'md:mr-8.3/100vw lg:mr-1/3'}`"
    >
      <div class="flex items-center justify-between w-full pb-4.5 md:pb-0 md:pr-8.3/100vw">
        <h2 class="pl-8 mr-8 whitespace-nowrap uppercase md:pl-28" :class="titleColor">
          {{ title }}
        </h2>
        <div class="h-px mr-8 bg-white/30 w-full grow"></div>
        <button-basic-link
          :to="link"
          class="px-8 ml-10 h-11.5 items-center justify-center bg-black text-white rounded-br-none font-semibold hidden whitespace-nowrap md:inline-flex md:ml-0 hover:bg-white hover:text-black transition duration-300"
        >
          {{ t("see-all") }}
        </button-basic-link>
      </div>
    </div>
    <div
      v-if="content"
      class="md:pb-18 lg:pb-60.5 md:rounded-b-2.5xl"
      :class="`${backgroundColor} ${invert ? 'md:ml-8.3/100vw lg:ml-1/3' : ' md:mr-8.3/100vw lg:mr-1/3'}`"
    ></div>
    <div class="md:bg-transparent" :class="backgroundColor">
      <div
        class="rounded-t-2.5xl md:-mt-18 lg:-mt-60.5 md:rounded-l-2.5xl md:rounded-tr-none flex bg-white md:ml-28 lg:ml-28 flex-wrap lg:flex-nowrap"
      >
        <p v-html="formattedContent" class="w-full lg:w-[16.375rem] flex-none px-8 md:px-18 lg:px-10 py-7.5 md:py-10 lg:py-15 box-content">
        </p>
        <div
          class="relative pl-8 rounded-tl-2.5xl pb-10 bg-white rounded-bl-2.5xl md:pt-3 md:pb-18 lg:py-15 md:px-0 md:rounded-l-2.5xl flex overflow-x-auto"
          :class="invert ? 'md:rounded-r-2.5xl md:pr-18 lg:pr-0' : 'md:rounded-l-2.5xl md:pl-18 lg:pl-0'"
        >
          <slider-overlay v-if="categories.length > 3" class="left-0 bg-gradient-to-r" :target="`slider-${uuid}`" id='slider-overlay-left'></slider-overlay>
          <slider-overlay v-if="categories.length > 3" class="right-0 bg-gradient-to-l" :target="`slider-${uuid}`" id='slider-overlay-right'></slider-overlay>
          <slider-controls-previous
            class="absolute top-1/2 -translate-y-1/2 left-3 lg:left-8 z-10 md:inline-flex"
            :target="`slider-${uuid}`"
            @disabled="overlay_previous_disabled = $event"
          ></slider-controls-previous>
          <slider-controls-next
            class="absolute top-1/2 -translate-y-1/2 right-3 lg:right-8 z-10 md:inline-flex"
            :target="`slider-${uuid}`"
            @disabled="overlay_next_disabled = $event"
          ></slider-controls-next>
          <slider-container :id="`slider-${uuid}`" ref="slider" class="px-2 outer-slider-container">
            <card-category
              v-for="category in categories"
              :key="category.id"
              :category="category"
              class="lg:first:ml-0"
              :class="
                invert ? 'first:mr-0 mr-5 md:ml-0  md:last:ml-0' : 'first:ml-0 ml-5 md:mr-0 md:last:mr-18 lg:last:mr-0'
              "
              :border-color="borderColor"
              :background-color="backgroundColor"
              :background-light-color="backgroundLightColor"
            ></card-category>
            <slot name="append"></slot>
          </slider-container>
        </div>
      </div>
    </div>
    <div class="flex justify-end md:hidden">
      <div class="flex justify-end w-2/3 py-7.5 pr-8 rounded-bl-2.5xl" :class="`${backgroundColor}`">
        <button-basic-link
          :to="link"
          class="px-8 ml-10 h-11.5 items-center justify-center bg-black text-white rounded-br-none font-semibold"
        >
          {{ t("see-all") }}
        </button-basic-link>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonBasicLink from "~/components/Buttons/ButtonBasicLink";
import CardCategory from "~/components/Cards/CardCategory";
import SliderContainer from "~/components/Sliders/SliderContainer";
import SliderControlsNext from "~/components/Sliders/SliderControlsNext";
import SliderControlsPrevious from "~/components/Sliders/SliderControlsPrevious";
import SliderOverlay from "~/components/Sliders/SliderOverlay";

export default {
  components: {
    SliderContainer,
    SliderControlsPrevious,
    SliderControlsNext,
    SliderOverlay,
    CardCategory,
    ButtonBasicLink,
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    const { id } = useAttrs();

    return { t, locale, localePath, uuid: id };
  },
  computed: {
    formattedContent() {
      return this.content.replace(/\\n/g, '<br>');
    }
  },
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    categories: Array,
    link: String,
    content: String,
    title: String,
    borderColor: String,
    backgroundLightColor: String,
    invert: Boolean,
    titleColor: String,
  },
};
</script>

<style lang="scss" scoped>
.outer-slider-container {
  //max-width: 910px;
  width: 100%;

  @media (min-width: 1536px) {
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "see-all": "Voir tout"
  },
  "en": {
    "see-all": "See all"
  }
}
</i18n>
